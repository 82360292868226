export const AppConstants = {
	IMAGE_BASE_URL: "https://www.uwglorder.co.uk/brandbank/images/",

	MISSING_IMAGE_URL: "https://www.uwglorder.co.uk/brandbank/missing_images/",

	IOS_APP_URL:
		"https://apps.apple.com/us/app/united-wholesale-grocers/id1076572009",

	DIRECT_TO_STORE_URL:
		"https://www.uwglorder.co.uk/category_focus/Supplier_Directory.pdf",

	POUND_SIGN: "£",

	Products_Limit: 30,

	Brochures_Page_Top_Text: "",
	Brochure_Page_Bottom_Text:
		"Please note our promotional brochure is applicable for collection only. For delivery enquiries please contact us on our delivery hotline.",

	CategoryFocus_Top_Text:
		"Click below to view the latest Plan for Profit core range guides and category focused merchandising guides",
	CategoryFocus_Bottom_Text: "",
};
