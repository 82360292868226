import React, { useEffect, useState } from "react";
import B1 from "./b1";
import B3A from "./b3a";
import B5A from "./b5a";
import B4 from "./b4";

const ThemeFive = ({ images, onClickOnUpload }) => {
	const [imagesHash, setImagesHash] = useState({});

	useEffect(() => {
		let updatedImagesHash = {};
		images.forEach((image) => {
			if (image.key == "b1") {
				updatedImagesHash.b1 = image;
			} else if (image.key == "b3-b") {
				updatedImagesHash.b3b = image;
			} else if (image.key == "b4") {
				updatedImagesHash.b4 = image;
			} else if (image.key == "b5-b") {
				updatedImagesHash.b5b = image;
			} else if (image.key == "b5-a") {
				updatedImagesHash.b5a = image;
			}
		});

		setImagesHash(updatedImagesHash);
	}, [images]);

	return (
		<>
			<B1
				onClick={() => {}}
				imageUrl={imagesHash.b1 == null ? "" : imagesHash.b1.imageFullPath}
			/>
			<article className="pad-t supplier-banner-wrap">
				<div className="row5">
					<div className="col-md-6">
						<B4
							imageUrl={
								imagesHash.b4 != null ? imagesHash.b4.imageFullPath : ""
							}
						/>
					</div>
					<div className="col-md-6">
						<div className="pad5-lr">
							<div className="row5">
								<div className="col-md-6">
									<B5A
										onClick={() => {}}
										imageUrl={
											imagesHash.b5a == null ? "" : imagesHash.b5a.imageFullPath
										}
									/>
								</div>
								<div className="col-md-6">
									<B5A
										onClick={() => {}}
										imageUrl={
											imagesHash.b5b == null ? "" : imagesHash.b5b.imageFullPath
										}
									/>
								</div>
								<div className="eor"></div>
							</div>
							<B3A
								onClick={() => {}}
								imageUrl={
									imagesHash.b3b == null ? "" : imagesHash.b3b.imageFullPath
								}
							/>
						</div>
					</div>

					<div className="eor"></div>
				</div>
			</article>
		</>
	);
};

export default ThemeFive;
