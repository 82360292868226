import React, { useState, useRef, useEffect } from "react";

import PreviousArrow from "./Previous-Arrow";
import NextArrow from "./Next-Arrow";
import Buynow from "../assets/buynow.svg";
import { APIService } from "../Services/APIService";
import { BannerService } from "../Services/BannerService";
import { Link, Navigate } from "react-router-dom";

const ImageSlider = () => {
	//Index of image displaying is slider
	const index = useRef(0);
	const prevIndex = useRef(0);
	const slideAuto = useRef(true);

	const [shouldShowImage1Element, setshouldShowImage1Element] = useState(true);
	// const [slideAuto, setSlideAuto] = useState(true);
	const [jSliderHeight, _setJSliderHeight] = useState(300);
	const [sliders, setSliders] = useState([]);
	const imageRef = useRef(null);
	const jSliderHeightRef = useRef(300);

	const setJSliderHeight = (height) => {
		jSliderHeightRef.current = height;
		_setJSliderHeight(height);
	};

	const handleMouseLeave = () => {
		slideAuto.current = true;
	};

	const handleMouseOver = () => {
		slideAuto.current = false;
	};

	var touchx1;
	const fingerstart = (event) => {
		touchx1 = event.touches[0].clientX;
	};
	const fingerend = (event) => {
		var touchx2 = event.changedTouches[0].clientX;
		if (touchx1 < touchx2) {
			handleClickOnPrevious();
		}
		if (touchx1 > touchx2) {
			handleClickOnNext();
		}
	};

	const autoNext = () => {
		if (slideAuto.current) {
			goNext();
		}
	};

	const handleClickOnPrevious = () => {
		if (index.current > 0) {
			setIndex(index.current - 1);
		}
	};
	const handleClickOnNext = () => {
		goNext();
	};
	const goNext = () => {
		if (sliders.length - index.current > 1) {
			setIndex(index.current + 1);
		} else {
			setIndex(0);
		}
	};
	const handleClickOnBuyNow = (index) => {
		BannerService.addBannerClick(sliders[index].id, (error) => {});
	};
	const setIndex = (number) => {
		prevIndex.current = index.current;
		index.current = number;
		BannerService.addBannerImpression(sliders[number].id, (error) => {});
		setshouldShowImage1Element(
			(shouldShowImage1Element) => !shouldShowImage1Element
		);
	};
	const getSliders = () => {
		APIService.get("get_top_banner_images.php", {}, (jsonResponse, error) => {
			if (error == null) {
				let slidersList = jsonResponse.data.BannerInfo;
				if (slidersList.length > 0) {
					setSliders((oldSliders) => slidersList);
					BannerService.addBannerImpression(slidersList[0].id);
				}
			}
		});
	};
	const imageAt = (index) => {
		if (window.innerWidth > 768) {
			return sliders[index].largeImage;
		} else {
			return sliders[index].smallImage;
		}
	};
	useEffect(() => {
		if (sliders.length > 0) {
			setJSliderHeight(imageRef.current.height);
		} else {
			getSliders();
		}
		window.onload = () => {
			handleResize();
		};
		const handleResize = () => {
			setJSliderHeight(imageRef.current.height);
		};
		window.addEventListener("resize", handleResize);

		window.addEventListener("refresh", handleResize);

		const interval = setInterval(() => {
			autoNext();
		}, 5000);

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("load", handleResize);
			clearInterval(interval);
		};
	}, [sliders]);
	return (
		<>
			{sliders.length > 0 && (
				<section
					id="jslider"
					style={{
						height: jSliderHeightRef.current,
					}}
					onMouseLeave={handleMouseLeave}
					onMouseEnter={handleMouseOver}
				>
					<PreviousArrow
						onClickHandler={handleClickOnPrevious}
						marginTop={jSliderHeight / 2}
					/>
					<NextArrow
						onClickHandler={handleClickOnNext}
						marginTop={jSliderHeight / 2}
					/>
					<div
						className="clipper"
						onTouchStart={fingerstart}
						onTouchEnd={fingerend}
						style={{ height: jSliderHeightRef.current }}
					>
						<ul className="slider">
							<li>
								<img
									ref={imageRef}
									src={
										shouldShowImage1Element
											? imageAt(index.current)
											: imageAt(prevIndex.current)
									}
									className={shouldShowImage1Element ? "fade-in" : "fade-out"}
								/>
							</li>
							<li>
								<img
									src={
										!shouldShowImage1Element
											? imageAt(index.current)
											: imageAt(prevIndex.current)
									}
									className={!shouldShowImage1Element ? "fade-in" : "fade-out"}
								/>
								<Link
									to={"/products/codes/" + sliders[index.current].productCodes}
								>
									<img
										className="buynow"
										src={Buynow}
										onClick={(e) => {
											handleClickOnBuyNow(index.current);
										}}
									/>
								</Link>
							</li>
						</ul>
					</div>

					<ul className="laslider_paging">
						{sliders.map((slider, listIndex) => {
							return (
								<li
									className={
										index.current !== listIndex ? " link" : "link link_on"
									}
									href="#"
									key={listIndex}
									onClick={() => setIndex(listIndex)}
								></li>
							);
						})}
					</ul>
				</section>
			)}{" "}
		</>
	);
};

export default ImageSlider;